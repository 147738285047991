<template>
  <div class="DashBoard">
    <div v-if="carregando == true" class="carregando">
      <img width="100" src="./../assets/images/chek.png" />
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div>
      <Topo @OpenMenuLateral="menuOpen = true"></Topo>
      <b-row
        :style="
          `background-color:${temaCor.topoApp};color:${temaCor.CorItensCabecalho}`
        "
      >
        <b-col class="container"><h4 class="my-3 ml-3">Ajuda</h4></b-col>
      </b-row>

      <div class="container mt-3">
        <b-row>
          <b-col cols="1"
            ><i
              class="fa fa-envelope icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a :href="'mailto:atendimento@clubecerto.com.br'">
              atendimento@clubecerto.com.br</a
            >
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="1"
            ><i
              class="fa fa-phone icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a :href="'phone:08009402195'"> 0800 940 2195</a>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="1"
            ><i
              class="fa fa-whatsapp icone"
              :style="`color:${temaCor.topoApp};`"
            ></i
          ></b-col>
          <b-col class="container">
            <a
              :href="
                'https://api.whatsapp.com/send?phone=55(31)%2099402-0965&text=Ol%C3%A1'
              "
              target="_blank"
            >
              (31) 99402-0965</a
            >
          </b-col>
        </b-row>

        <b-row class="mt-3" style="margin-bottom:100px">
          <b-col class="container text-center">
            <a
              :href="'http://www.clubecerto.com.br/contato'"
              target="_blank"
              class="linksite"
              ><i class="fa fa-envelope-o icone"></i> Enviar Mensagem</a
            >
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import MenuRodape from "./MenuRodape";
import Topo from "./Topo";
import MenuLateral from "./MenuLateral";
import { Carousel, Slide } from "vue-carousel";
import service from "../services";
import moment from "moment";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      flipped: false,
      dados: {},
      temaCor: {},
      carregando: false,
      heightGeral: { height: "375px" },
      nome: null,
      cpf: null,
      cartaoFrente: null,
      cartaoVerso: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, MenuRodape, MenuLateral, Carousel, Slide },
  methods: {
    toggleCard() {
      this.flipped = !this.flipped;
    },
    formataRegional(id) {
      if (id) return id.toString().padStart(4, "0");
      else return "0000";
    },
    formataId(id) {
      if (id) return id.toString().padStart(6, "0");
      else return "000000";
    },
    formataDesde(data) {
      if (data) return moment(data).format("MM/YYYY");
    },
    formataAno(data) {
      if (data) return moment(data).format("YYYY");
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      const nome = localStorage.getItem("nome");
      const cpf = localStorage.getItem("cpf");
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
      this.temaCor.CorTextoCartaoVirtual = dadosEmpresa.CorTextoCartaoVirtual;
      console.log(this.temaCor);
      this.cartaoFrente = dadosEmpresa.Frente;
      this.cartaoVerso = dadosEmpresa.Verso;
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
        this.nome = nome;
        this.cpf = cpf;
      }
    },
    getSize() {
      console.log(this.heightGeral);
      this.heightGeral.height = `${screen.height - 10}px`;
      console.log("////////////////////", document.height, screen.height);
      console.log(this.heightGeral);
    },
  },
  mounted() {
    this.verificaToken();
    this.getSize();
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
body {
  overflow-x: hidden;
}
.container {
  font-size: 15px;
}
a {
  color: #333;
}
.icone {
  font-size: 26px;
}

.linksite {
  border: 1px solid #333;
  border-radius: 10px;
  padding: 10px;
  i {
    margin-right: 10px;
  }
}
</style>
